import { useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import StringInput from './StringInput';

interface MultiLineStringFieldProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	field: 'description' | 'modelingScope' | 'vrDeliveryTimeline' | 'photoReportTimeline';
}

export default function MultiLineStringField({
	isActive,
	handleChange,
	field,
}: MultiLineStringFieldProps) {
	const { user } = useContext(AuthContext);

	return (
		<div className="infoRow multilineRow">
			<FieldLabel label={multiLineLabels[field]} />

			<StringInput
				field={field}
				onBlur={handleChange}
				isActive={Boolean(user?.isAdmin && isActive)}
				multiline
			/>
		</div>
	);
}

const multiLineLabels = {
	description: 'Scanning Notes',
	modelingScope: 'BIM Notes',
	vrDeliveryTimeline: 'VR Delivery Timeline',
	photoReportTimeline: 'Photo Report Timeline',
};
