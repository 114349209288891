import {
	cpuOptions,
	// getAnonUploadLink,
	parseNumFromStr,
} from '../../../../../utils';
import { CellProps, Column, Row } from 'react-table';
import { EquipmentColumnComponent } from './components/EquipmentColumnComponent';
import { ProjectData, ProjectStatus } from '../../../../../types';
import AccountManagerCell from './components/cells/AccountManagerCell';
import OrganizationsCell from './components/cells/OrganizationsCell';
import MultiDateTimeCell from './components/cells/MultiDateTimeCell';
import LastContactedCell from './components/cells/LastContactedCell';
import SingleStringCell from './components/cells/SingleStringCell';
import MultiStringCell from './components/cells/MultiStringCell';
import MultiUserCell from './components/cells/MultiUserCell';
import CheckboxCell from './components/cells/CheckboxCell';
import DateTimeCell from './components/cells/DateTimeCell';
import SubTotalCell from './components/cells/SubTotalCell';
// import InvoicesCell from './components/cells/InvoicesCell';
import AddressCell from './components/cells/AddressCell';
import StatusCell from './components/cells/StatusCell';
import CostCell from './components/cells/CostCell';
import FileDnd from '../../../FileDnd';
import React from 'react';
// import UploadPasswordCell from './components/cells/UploadPasswordCell';
// import LinkWithCopyButton from '../../../LinkWithCopyButton';
// import ScopesCell from './components/cells/ScopesCell';
// import AddressCell from './cells/AddressCell';
// import { Checkbox } from '@mui/material';

/**
 * Columns that are only visible for admins
 */
const restrictedColumns = [
	'Sub-Total',
	'Margin',
	'Scanning Cost',
	'Data Processing Cost',
	'BIM Cost',

	'',
	'Proposal Documents',
	'Accounts Payable',
];

const contractorExcludedColumns = ['BIM Technician(s)'];

export const sortableColumns: (keyof ProjectData)[] = [
	'deliveryTimestamp',
	'modelingStartTimestamp',
	'modelingDueTimestamp',
	'orgs',
	'captureTimestamps',
];

const dateColumnWidth = 140;

export type ProjectFieldColumn = Column<ProjectData> & {
	projectField: keyof ProjectData | null;
	// Cell:
	// 	| Renderer<
	// 			CellProps<ProjectData, string[]> & {
	// 				rowEditing?: boolean;
	// 				setRowEditing?: React.Dispatch<React.SetStateAction<boolean>>;
	// 			}
	// 	  >
	// 	| undefined;
};

export const getStatusColumns = (
	status: ProjectStatus,
	user: any,
	setProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>
) => {
	const columns = matchStatusColumns(status, setProjects);
	if (!user?.isAdmin) {
		const isContractor = !!user?.isContractor;
		const filteredColumns: ProjectFieldColumn[] = [];

		for (const column of columns) {
			if (column.Header && typeof column.Header === 'string') {
				const adminOnly = restrictedColumns.includes(column.Header);
				const contractorRestricted =
					isContractor && contractorExcludedColumns.includes(column.Header);

				if (!adminOnly && !contractorRestricted) filteredColumns.push(column);
			}
		}
		return filteredColumns;
	} else return columns;
};

export const matchStatusColumns = (
	status: ProjectStatus,
	setProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>
): ProjectFieldColumn[] => {
	const commonColumns = [
		checkboxColumn,
		statusColumn,
		organizationsColumn,
		siteNumberColumn,
		addressColumn,
	];
	const commonColumnsWithInvoice = [
		checkboxColumn,
		statusColumn,
		organizationsColumn,
		siteNumberColumn,
		addressColumn,
		// invoicesColumn,
		// skuColumn,
	];

	switch (status) {
		case 'Proposal Pending':
			return [
				checkboxColumn,
				statusColumn,
				organizationsColumn,
				addressColumn,
				subTotalColumn,
				marginColumn,
				accountManagerColumn,
				proposalFilesColumn(setProjects),
				scanningCostColumn,
				registrationCostColumn,
				modelingCostColumn,
			];
		case 'Proposal Sent':
			return [
				checkboxColumn,
				statusColumn,
				organizationsColumn,
				addressColumn,
				subTotalColumn,
				marginColumn,
				accountManagerColumn,
				lastContactedColumn,
				proposalFilesColumn(setProjects),
				scanningCostColumn,
				registrationCostColumn,
				modelingCostColumn,
			];
		case 'To Be Scheduled':
			return [
				checkboxColumn,
				statusColumn,
				organizationsColumn,
				addressColumn,
				// invoicesColumn,
				// sqftColumn,
				captureTimestampsColumn,
				equipmentColumn,
				techniciansColumn,
			];
		case 'On-Site Capture Complete':
			return [
				checkboxColumn,
				statusColumn,
				organizationsColumn,
				addressColumn,

				techniciansColumn,
				registrationManagersColumn,
				vrLinkColumn,
				pointCloudColumn,
				photosColumn,
			];
		case 'Registration':
			return [
				checkboxColumn,
				statusColumn,
				organizationsColumn,
				cpuColumn,
				addressColumn,

				registrationManagersColumn,
				bimTechniciansColumn,
			];
		case 'Ready For Modeling':
			return [
				...commonColumnsWithInvoice,
				modelersColumn,
				bimTechniciansColumn,
				qaqcManagersColumn,
				designSoftwareColumn,
				// modelingScopeColumn,
				// modelingCostColumn,
				modelingStartTimestampColumn,
				modelingDueTimestampColumn,
				deliveryTimestampColumn,
				// uploadLinkColumn,
				// uploadPasswordColumn,
			];
		case 'Modeling In Progress':
			return [
				...commonColumnsWithInvoice,
				modelersColumn,
				bimTechniciansColumn,
				qaqcManagersColumn,
				designSoftwareColumn,
				modelingStartTimestampColumn,
				modelingDueTimestampColumn,
				deliveryTimestampColumn,
				// uploadLinkColumn,
				// uploadPasswordColumn,
			];
		case 'QA/QC':
			return [
				...commonColumnsWithInvoice,
				modelersColumn,
				bimTechniciansColumn,
				qaqcManagersColumn,
				designSoftwareColumn,
				modelingStartTimestampColumn,
				modelingDueTimestampColumn,
				deliveryTimestampColumn,
				// uploadLinkColumn,
				// uploadPasswordColumn,
			];
		case 'Pending Payment':
		case 'Pending Payment / Delivered':
			return [
				...commonColumnsWithInvoice,
				subTotalColumn,
				deliveryTimestampColumn,
				accountsPayableColumn,
			];
		case 'Delivered':
			return [...commonColumnsWithInvoice];
		case 'Sample':
		case 'Template':
		case 'Archive':
			return [...commonColumns];
		default:
			return [];
	}
};

const checkboxColumn: ProjectFieldColumn = {
	id: 'checkbox',
	Cell: ({ row }: { row: Row<ProjectData> }) => {
		return <CheckboxCell project={row.original} />;
	},
	width: 42,
	projectField: null,
};

const statusColumn: ProjectFieldColumn = {
	Header: 'Status',
	id: 'projectStatus',
	Cell: ({ row, projectData, rowEditing, setRowEditing }: any) => (
		<StatusCell
			row={row}
			projectData={row.original}
			rowEditing={rowEditing}
			setRowEditing={setRowEditing}
		/>
	),
	width: 350,
	projectField: 'projectStatus',
};

const organizationsColumn: ProjectFieldColumn = {
	Header: 'Organization(s)',
	Cell: ({ row, rowEditing, sortBy }: any) => {
		return <OrganizationsCell row={row} rowEditing={rowEditing} sortBy={sortBy} />;
	},
	width: 200,
	projectField: 'orgs',
};

const addressColumn: ProjectFieldColumn = {
	Header: 'Address',
	Cell: ({ row, rowEditing }: any) => <AddressCell row={row} rowEditing={rowEditing} />,
	width: 400,
	projectField: 'address',
};

const accountManagerColumn: ProjectFieldColumn = {
	Header: 'Account Manager',
	Cell: ({ row }: { row: Row<ProjectData> }) => (
		<AccountManagerCell projectData={row.original} />
	),
	projectField: 'accountManager',
};

const lastContactedColumn: ProjectFieldColumn = {
	Header: 'Last Contacted',
	Cell: ({ row, rowEditing }: any) => (
		<LastContactedCell row={row} rowEditing={rowEditing} />
	),
	width: dateColumnWidth,
	projectField: 'lastContacted',
};

const subTotalColumn: ProjectFieldColumn = {
	Header: 'Sub-Total',
	Cell: ({ row, rowEditing }: any) => {
		return <SubTotalCell row={row} rowEditing={rowEditing} />;
	},
	width: 100,
	projectField: 'subTotal',
};

// const projectNotesColumn: ProjectFieldColumn = {
// 	Header: 'Project Notes / Access',
// 	Cell: ({ row: { original: project } }: any) => {
// 		return <span>{project.description ? project.description : '-'}</span>;
// 	},
// 	accessor: (row: any) => (row.description ? row.description : '-'),
// 	disableSortBy: true,
// 	width: 200,
// };

const captureTimestampsColumn: ProjectFieldColumn = {
	Header: 'Site Visit Date(s)',
	Cell: ({ row, rowEditing, sortBy }: any) => {
		return (
			<MultiDateTimeCell
				row={row}
				rowEditing={rowEditing}
				field="captureTimestamps"
				title="Site Visit Date(s)"
				sortBy={sortBy}
			/>
		);
	},
	width: dateColumnWidth,
	projectField: 'captureTimestamps',
};

const modelersColumn: ProjectFieldColumn = {
	Header: 'BIM Team',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiStringCell
				row={row}
				rowEditing={rowEditing}
				field="modelers"
				title="BIM Team"
			/>
		);
	},
	width: 200,
	projectField: 'modelers',
};

const bimTechniciansColumn: ProjectFieldColumn = {
	Header: 'BIM Technician(s)',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiUserCell
				row={row}
				rowEditing={rowEditing}
				field="bimTechnicians"
				title="BIM Technician(s)"
			/>
		);
	},
	width: 200,
	projectField: 'bimTechnicians',
};

const modelingStartTimestampColumn: ProjectFieldColumn = {
	Header: 'Modeling Start Date',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<DateTimeCell row={row} rowEditing={rowEditing} field="modelingStartTimestamp" />
		);
	},
	width: dateColumnWidth,
	projectField: 'modelingStartTimestamp',
};

const modelingDueTimestampColumn: ProjectFieldColumn = {
	Header: 'Modeling Due Date',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<DateTimeCell row={row} rowEditing={rowEditing} field="modelingDueTimestamp" />
		);
	},
	width: dateColumnWidth,
	projectField: 'modelingDueTimestamp',
};

const deliveryTimestampColumn: ProjectFieldColumn = {
	Header: 'Delivery Date',
	Cell: ({ row, rowEditing }: any) => {
		return <DateTimeCell row={row} rowEditing={rowEditing} field="deliveryTimestamp" />;
	},
	width: dateColumnWidth,
	projectField: 'deliveryTimestamp',
};

// const signedProposalColumn: ProjectFieldColumn = {
// 	Header: 'Signed Proposal',
// 	Cell: ({ row: { original: project } }: any) => (
// 		<Checkbox
// 			checked={project.signedProposal ?? false}
// 			// onChange={(e: any) => {
// 			// 	updateFirebase(project, e.target.checked, 'signedProposal');
// 			// 	// updateFirebase(project, e.target.checked, 'signedProposal', false);
// 			// 	// setData(prev => {
// 			// 	// 	const tmp = [...prev];
// 			// 	// 	const idx = tmp.findIndex(p => p.id === project.id);
// 			// 	// 	tmp.splice(idx, 1, { ...project, signedProposal: e.target.checked });
// 			// 	// 	return tmp;
// 			// 	// });
// 			// }}
// 			sx={{
// 				color: '#f6f6f6',
// 				'&.Mui-checked': {
// 					color: 'white',
// 				},
// 			}}
// 		/>
// 	),
// 	accessor: (row: any) => (row.signedProposal ? '✓' : '-'),
// 	width: 200,
// };

const marginColumn: ProjectFieldColumn = {
	Header: 'Margin',
	Cell: ({ row: { original: project } }: { row: { original: ProjectData } }) => {
		let margin = 0;
		const subTotal = parseNumFromStr(project.subTotal);
		const totalCost =
			(project.scanningCost || 0) +
			(project.registrationCost || 0) +
			(project.modelingCost || 0);
		if (subTotal !== 0) {
			margin = (1 - totalCost / subTotal) * 100;
		} else {
			margin = 0;
		}

		return <span>{project.subTotal ? `${margin.toFixed(2)} %` : '-'}</span>;
	},
	projectField: null,
	width: 80,
};

const scanningCostColumn: ProjectFieldColumn = {
	Header: 'Scanning Cost',
	Cell: ({ row, rowEditing }: any) => {
		return <CostCell row={row} rowEditing={rowEditing} costType="scanningCost" />;
	},
	projectField: 'scanningCost',
};

const registrationCostColumn: ProjectFieldColumn = {
	Header: 'Data Processing Cost',
	Cell: ({ row, rowEditing }: any) => {
		return <CostCell row={row} rowEditing={rowEditing} costType="registrationCost" />;
	},
	projectField: 'registrationCost',
};

const modelingCostColumn: ProjectFieldColumn = {
	Header: 'BIM Cost',
	Cell: ({ row, rowEditing }: any) => {
		return <CostCell row={row} rowEditing={rowEditing} costType="modelingCost" />;
	},
	projectField: 'modelingCost',
};

const proposalFilesColumn = (
	setProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>
) => {
	const column: ProjectFieldColumn = {
		Header: 'Proposal Documents',
		Cell: ({ row: { original: project } }: { row: { original: ProjectData } }) => {
			return <FileDnd projectProp={project} field="proposal" setProjects={setProjects} />;
		},
		width: 160,
		projectField: 'proposal',
	};
	return column;
};

const equipmentColumn: ProjectFieldColumn = {
	Header: 'Equipment',
	Cell: ({ row, rowEditing }: any) => {
		return <EquipmentColumnComponent row={row} rowEditing={rowEditing} />;
	},
	projectField: 'equipment',
};

const techniciansColumn: ProjectFieldColumn = {
	Header: 'Scanning Technician(s)',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiUserCell
				row={row}
				rowEditing={rowEditing}
				field="scanningTechnicians"
				title="Scanning Technician(s)"
			/>
		);
	},
	width: 200,
	projectField: 'scanningTechnicians',
};

const registrationManagersColumn: ProjectFieldColumn = {
	Header: 'Registration Technician(s)',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiUserCell
				row={row}
				rowEditing={rowEditing}
				field="registrationManagers"
				title="Registration Technician(s)"
			/>
		);
	},
	width: 200,
	projectField: 'registrationManagers',
};

const qaqcManagersColumn: ProjectFieldColumn = {
	Header: 'QA/QC Technician(s)',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiUserCell
				row={row}
				rowEditing={rowEditing}
				field="qaQcManagers"
				title="QA/QC Technician(s)"
			/>
		);
	},
	width: 200,
	projectField: 'qaQcManagers',
};

// const modelingScopeColumn: ProjectFieldColumn = {
// 	Header: 'BIM Notes',
// 	Cell: ({ row, rowEditing }: any) => {
// 		return <ScopesCell row={row} rowEditing={rowEditing} field="modelingScope" />;
// 	},
// 	projectField: 'modelingScope',
// };

const designSoftwareColumn: ProjectFieldColumn = {
	Header: 'Design Software',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiStringCell
				row={row}
				rowEditing={rowEditing}
				field="designSoftware"
				title="Design Software"
			/>
		);
	},
	projectField: 'designSoftware',
};

const accountsPayableColumn: ProjectFieldColumn = {
	Header: 'Accounts Payable',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiStringCell
				row={row}
				rowEditing={rowEditing}
				field="accountsPayable"
				title="Accounts Payable"
			/>
		);
	},
	projectField: 'accountsPayable',
};

// const uploadLinkColumn: ProjectFieldColumn = {
// 	Header: 'Upload Link',
// 	Cell: ({ row: { original: project } }: { row: { original: ProjectData } }) => {
// 		return (
// 			<LinkWithCopyButton
// 				link={getAnonUploadLink(project) || ''}
// 				title={'Public upload'}
// 			/>
// 		);
// 	},
// 	projectField: null,
// };

// const uploadPasswordColumn: ProjectFieldColumn = {
// 	Header: 'Upload Password',
// 	Cell: ({ row: { original: project } }: { row: { original: ProjectData } }) => {
// 		return <UploadPasswordCell projectData={project} />;
// 	},
// 	projectField: null,
// };

const siteNumberColumn: ProjectFieldColumn = {
	Header: 'Site Number',
	Cell: ({ row, rowEditing }: any) => {
		return <SingleStringCell row={row} rowEditing={rowEditing} field="storeNumber" />;
	},
	projectField: 'storeNumber',
};

const cpuColumn: ProjectFieldColumn = {
	Header: 'CPU',
	Cell: ({ row, rowEditing }: any) => {
		return (
			<MultiStringCell
				row={row}
				rowEditing={rowEditing}
				field="cpu"
				title="CPU"
				options={cpuOptions}
			/>
		);
	},
	projectField: 'cpu',
};

const vrLinkColumn: ProjectFieldColumn = {
	Header: 'VR Link',
	Cell: (cellProps: CellProps<ProjectData, any>) => {
		const hasLinks = Boolean(cellProps.row.original.matterport?.length);
		return <span>{hasLinks ? '✓' : '-'}</span>;
	},
	projectField: 'matterport',
};

const pointCloudColumn: ProjectFieldColumn = {
	Header: 'Point Cloud',
	Cell: (cellProps: CellProps<ProjectData, any>) => {
		const hasFiles = Boolean(cellProps.row.original.pointCloud?.length);
		return <span>{hasFiles ? '✓' : '-'}</span>;
	},
	projectField: 'pointCloud',
};

const photosColumn: ProjectFieldColumn = {
	Header: 'Photos',
	Cell: (cellProps: CellProps<ProjectData, any>) => {
		const hasFiles = Boolean(cellProps.row.original.photos?.length);
		return <span>{hasFiles ? '✓' : '-'}</span>;
	},
	projectField: 'photos',
};
