import { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { YellowIconButton } from '../../../styled-components/buttons';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { createProposal } from '../../../../firebase';
import { SnackContext } from '../../../../context/SnackProvider';
import { FirebaseFile } from '../../../../types';
import Spinner from '../../../reusable-components/Spinner';

export default function GenerateProposalButton() {
	const { user } = useContext(AuthContext);
	const { project, setProject } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		if (project && project.id) {
			try {
				setLoading(true);
				const res = await createProposal({ projectId: project.id });
				// @ts-expect-error res.data is of type 'unknown'.
				const newFile = res.data.newFile as FirebaseFile;
				newFile.lastUpdated = new Date(newFile.lastUpdated);
				newFile.uploadDate = new Date(newFile.uploadDate);

				setProject(prev => {
					if (prev) {
						return {
							...prev,
							proposal: [...(prev.proposal || []), newFile],
						};
					} else return prev;
				});

				setSnackbarProps({
					open: true,
					severity: 'success',
					message: 'New proposal generated!',
				});
			} catch (err) {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: 'Could not generate proposal',
				});
				console.error(err);
			} finally {
				setLoading(false);
			}
		}
	};

	return !user?.isAdmin ? null : !loading ? (
		<YellowIconButton title="Generate Proposal" onClick={handleClick}>
			<NoteAddIcon />
		</YellowIconButton>
	) : (
		<Spinner />
	);
}
