import FormWrapper from './components/FormWrapper';
import styled from 'styled-components';
import { FormNavigationMenu } from '../../form-components';
import theme from '../../../styles/theme';
import { useMediaQuery } from '@mui/system';
import { createContext, useContext, useEffect, useState } from 'react';
import { FormNode } from '../ProjectUtilityFormV2/utils/types';
import { useSearchParams } from 'react-router-dom';
import { FormV2Context } from '../ProjectUtilityFormV2/context/FormV2Context.types';

interface FormProps {
	formId: string;
	projectId: string;
	isTemplate?: boolean;
}

interface MenuReorderContextType {
	expandedList: string[];
	toggleItem: (id: string) => void;
	toggleAllItems: (id: string[]) => void;
	setSelectedItem: React.Dispatch<React.SetStateAction<FormNode | undefined>>;
	selectedItem: FormNode | undefined;
}
export const MenuReorderContext = createContext({} as MenuReorderContextType);

export default function Form({ formId, projectId }: FormProps) {
	const matches = useMediaQuery('(max-width:1024px)');
	const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
	const [navigateToNode, setNavigateToNode] = useState<FormNode | undefined>();
	const [selectedItem, setSelectedItem] = useState<FormNode | undefined>();

	const [expandedList, setExpandedList] = useState<string[]>([]);
	const { currentNode } = useContext(FormV2Context);

	const [searchParams] = useSearchParams();
	const itemId = searchParams.get('itemId');

	useEffect(() => {
		if (itemId) {
			setTimeout(() => {
				const item = document.getElementById(`${itemId}-scroll`);
				console.log(item);
				item?.scrollIntoView();
			}, 1800);
			setTimeout(() => {
				const dialog = document.getElementById(`${itemId}-comments`);
				dialog?.click();
			}, 2500);
		}
	}, [itemId, currentNode]);

	const toggleItem = (id: string) => {
		if (expandedList.includes(id)) setExpandedList(expandedList.filter(e => e !== id));
		else setExpandedList([...expandedList, id]);
	};

	const toggleAllItems = (id: string[]) => {
		setExpandedList(id);
	};

	const scrollToAndHighlight = (itemId: string) => {
		let item = document.getElementById(itemId);
		item?.scrollIntoView();

		item?.animate(
			[
				// Keyframes
				{ backgroundColor: theme.palette.grey[500] },
				{ backgroundColor: 'initial' },
			],
			{
				duration: 1500,
				easing: 'ease-out',
			}
		);

		item = document.getElementById(itemId);
		item?.scrollIntoView();
	};

	useEffect(() => {
		if (navigateToNode) scrollToAndHighlight(navigateToNode.id);
	}, [navigateToNode]);

	return (
		<MenuReorderContext.Provider
			value={{
				expandedList,
				toggleItem,
				toggleAllItems,
				setSelectedItem,
				selectedItem,
			}}>
			<Container>
				{!matches && (
					<MenuContainer>
						<FormNavigationMenu
							formId={formId}
							projectId={projectId}
							onClick={setNavigateToNode}
						/>
					</MenuContainer>
				)}
				<FormContainer>
					<FormWrapper
						formId={formId}
						projectId={projectId}
						indentationWidth={matchesSM ? 25 : 50}
						navigateToNode={navigateToNode}
					/>
				</FormContainer>
			</Container>
		</MenuReorderContext.Provider>
	);
}

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	/* color: #ffb310; */
	background-color: ${theme.palette.secondary.main};
	height: 100%;
	overflow-y: auto;
	padding: 0 5px;
`;

const MenuContainer = styled.div`
	flex-shrink: 0;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	background-color: ${theme.palette.secondary.main};
`;
