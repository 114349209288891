import { AuthContext } from '../../../../../../context/AuthProvider';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import { YellowIconButton } from '../../../../../styled-components/buttons';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PendingUser, SharedTeam, SharedUser } from '../types';
import { getSharedUsers } from '../../../../../../firebase';
import EmailSharingBox from '../../../../../reusable-components/EmailSharingBox';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';

import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import Spinner from '../../../../../reusable-components/Spinner';
import SharedUsersAndTeams from './SharedUsersAndTeams';
import { ProjectData } from '../../../../../../types';

type ShareProjectButtonProps = {
	projectSelected?: ProjectData;
};

const ShareProjectButton = ({ projectSelected }: ShareProjectButtonProps) => {
	const { project } = useContext(ProjectContext);
	const { user } = useContext(AuthContext);

	const projectId = useMemo(
		() => (projectSelected ? projectSelected?.id : project?.id),
		[project?.id, projectSelected]
	);

	const isContractor = Boolean(user?.isContractor);

	const [showingDialog, setShowingDialog] = useState(false);

	// Keeps track of component's mount status to avoid memory leaks
	// from performing React state updates on an unmounted component
	const _isMounted = useRef(true);
	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);
	const [pendingUsers, setPendingUsers] = useState<PendingUser[]>([]);
	const [sharedTeams, setSharedTeams] = useState<SharedTeam[]>([]);
	const [loadingUsers, setLoadingUsers] = useState(true);

	const getSetMembers = useCallback(async () => {
		if (!projectId) return;

		setLoadingUsers(true);
		const res = await getSharedUsers({ projectId: projectId });
		if (_isMounted.current) {
			const data = res.data as any;
			setSharedUsers(data.sharedUsers ?? []);
			setPendingUsers(data.pendingUsers ?? []);
			setSharedTeams(data.sharedTeams ?? []);
		}
		setLoadingUsers(false);
	}, [projectId]);

	useEffect(() => {
		if (projectId && user) {
			if (!isContractor) getSetMembers();
		}
	}, [getSetMembers, isContractor, projectId, user]);

	const handleClose = () => {
		setShowingDialog(false);
	};

	return (
		<>
			{loadingUsers ? (
				<Spinner />
			) : (
				<YellowIconButton
					title="Manage shared users for this project"
					size="large"
					onClick={() => setShowingDialog(true)}>
					<StyledShareIcon />
				</YellowIconButton>
			)}

			<Dialog open={showingDialog} onClose={handleClose} style={{ zIndex: 999 }}>
				<DialogTitle>Shared Users and Teams</DialogTitle>
				<StyledDialogContent>
					{!loadingUsers ? (
						<>
							{!isContractor ? (
								<SharedUsersAndTeams
									sharedUsers={sharedUsers}
									pendingUsers={pendingUsers}
									sharedTeams={sharedTeams}
									getSetMembers={getSetMembers}
								/>
							) : null}
							<EmailSharingBox handleUpdateList={getSetMembers} />
						</>
					) : (
						<LoadingScreen message="Loading..." />
					)}
				</StyledDialogContent>
				<DialogActions>
					<Button onClick={handleClose}>close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ShareProjectButton;

const StyledShareIcon = styled(ShareIcon)`
	color: #ffb310;
`;

const StyledDialogContent = styled(DialogContent)`
	background: dimgrey;
	gap: 20px;

	@media screen and (max-width: 600px) {
		padding: 5px !important;
	}
`;
