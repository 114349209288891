import { useContext, useState, useEffect } from 'react';
import {
	Row,
	// HeaderProps,
	useFilters,
	useFlexLayout,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	// useSortBy,
	useTable,
} from 'react-table';
import { getLeadColumns } from './columns';
import styled from 'styled-components';
import LeadsTableRow from './components/LeadsTableRow';
import Graphs from './components/Graphs';
import LeadsHeaderCell from './components/cells/LeadsHeaderCell';
import { Lead, LeadStatus, SortState } from '../../types';
import { LeadsContext } from '../LeadsProvider';
import LoadingScreen from '../../../../components/reusable-components/LoadingScreen';
import { useSearchParams } from 'react-router-dom';
import { UserObj } from '../../../../types';

const columns = getLeadColumns();

interface LeadsTableProps {
	query: string;
	showGraphs: boolean;

	accountManagerFilter: UserObj | undefined;
	leadStatusFilter: LeadStatus | '';
	peopleFilter: string[];

	setSelectedRows: React.Dispatch<React.SetStateAction<Row<Lead>[]>>;
	deselectAllRows: boolean;
	setDeselectAllRows: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LeadsTable({
	query,
	showGraphs,

	accountManagerFilter,
	leadStatusFilter,
	peopleFilter,

	setSelectedRows,
	deselectAllRows,
	setDeselectAllRows,
}: LeadsTableProps) {
	const [searchParams] = useSearchParams();

	const { leads, fetchingLeads } = useContext(LeadsContext);

	const [filteredLeads, setFilteredLeads] = useState(leads);
	const [sortState, setSortState] = useState<SortState>({ sortType: 'asc' });

	useEffect(() => {
		setFilteredLeads(() => {
			let filtered = [...leads];

			console.log(accountManagerFilter);
			if (accountManagerFilter) {
				filtered = filtered.filter(
					lead => lead.accountManager?.id === accountManagerFilter.id
				);
			}
			if (leadStatusFilter) {
				filtered = filtered.filter(lead => lead.status === leadStatusFilter);
			}
			if (peopleFilter.length) {
				filtered = filtered.filter(lead => {
					const leadPerson = `${lead.firstName} ${lead.lastName}`.trim().toLowerCase();
					return peopleFilter.some(person => leadPerson.includes(person.toLowerCase()));
				});
			}

			const field = sortState.field;
			if (field) {
				filtered.sort((a, b) => {
					if (typeof a[field] === 'string') {
						const string1 = ((a[field] as string) || '').toLowerCase();
						const string2 = ((b[field] as string) || '').toLowerCase();
						if (string1 < string2) return -1;
						else if (string1 > string2) return 1;
						else return 0;
					} else {
						const date1 = (a[field] as Date) || new Date(0);
						const date2 = (b[field] as Date) || new Date(0);
						if (date1.valueOf() < date2.valueOf()) return -1;
						else if (date1.valueOf() > date2.valueOf()) return 1;
						else return 0;
					}
				});
				if (sortState.sortType === 'desc') filtered.reverse();
			}

			return filtered;
		});
	}, [
		leadStatusFilter,
		leads,
		accountManagerFilter,
		peopleFilter,
		sortState.field,
		sortState.sortType,
	]);

	const [controlledPageIndex, setControlledPageIndex] = useState(0);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: {
			pageIndex,
			pageSize,
			// selectedRowIds, // JSON object containing indexes of selected rows (before filtering) as keys
			// sortBy,
		},
		// preGlobalFilteredRows,
		setGlobalFilter,
		rows, // Array of rows after being globally filtered
		selectedFlatRows, // Array of all selected rows after being filtered
		toggleAllRowsSelected,
	} = useTable(
		{
			data: filteredLeads,
			columns: columns,
			initialState: {
				pageSize: 15,
				pageIndex: controlledPageIndex,
			},

			// De-selects all selected rows, even the ones not currently displayed.
			// See https://stackoverflow.com/questions/65096897/remove-all-selected-rows-even-though-im-in-other-page-using-react-table-with-co
			// for details.
			stateReducer: (newState, action) => {
				switch (action.type) {
					case 'toggleAllRowsSelected':
						return {
							...newState,
							selectedRowIds: {},
						};
					default:
						return newState;
				}
			},

			// Prevents table reset when editing data
			autoResetGlobalFilter: false,
			autoResetFilters: false,
			autoResetHiddenColumns: false,
			autoResetRowState: false,
			autoResetSelectedRows: false,
			autoResetSortBy: false,
			autoResetPage: false,
		},
		useFilters,
		useGlobalFilter,
		// useSortBy,
		usePagination,
		useRowSelect,
		useFlexLayout
	);
	const getPageMin = () => {
		return pageIndex * pageSize + 1;
	};
	const getPageMax = () => {
		return pageIndex + 1 === pageCount ? rows.length : (pageIndex + 1) * pageSize;
	};
	/**
	 * Calculates new page index based on top row after resizing page size
	 */
	const configurePageSize = (size: number) => {
		const newPageIndex = Math.floor((pageIndex * pageSize) / size);
		setControlledPageIndex(newPageIndex);
		setPageSize(size);
	};

	useEffect(() => {
		const scrollFunc = (e: Event) => {
			const target = e.target as HTMLElement;
			if (target.id === 'table-body') {
				const headerRow = document.getElementById('header-row');
				if (headerRow) headerRow.scrollLeft = target.scrollLeft;
			}
		};
		document.addEventListener('scroll', scrollFunc, true);
		return () => document.removeEventListener('scroll', scrollFunc);
	}, []);

	// Auto refresh.
	useEffect(() => {
		if (searchParams.get('refresh') === 'true') {
			setTimeout(() => {
				window.location.reload();
			}, 60000);
		}
	}, [searchParams]);

	useEffect(() => {
		setGlobalFilter(query);
	}, [query, setGlobalFilter]);

	useEffect(() => {
		setSelectedRows(selectedFlatRows);
	}, [selectedFlatRows, setSelectedRows]);

	useEffect(() => {
		if (deselectAllRows) {
			toggleAllRowsSelected(false);
			setDeselectAllRows(false);
		}
	}, [deselectAllRows, setDeselectAllRows, toggleAllRowsSelected]);

	return !fetchingLeads ? (
		<Container>
			{!showGraphs ? (
				<TableContainer>
					{headerGroups.map(headerGroup => (
						<HeaderRow {...headerGroup.getHeaderGroupProps()} id="header-row">
							{headerGroup.headers.map(column => (
								<LeadsHeaderCell
									column={column}
									sortState={sortState}
									setSortState={setSortState}
									key={column.id}
								/>
							))}
						</HeaderRow>
					))}

					<TableBody {...getTableProps()} id="table-body">
						{page.map((row, rowIdx) => {
							prepareRow(row);
							return (
								<LeadsTableRow
									row={row}
									rowIdx={rowIdx}
									pageIndex={pageIndex}
									pageSize={pageSize}
									key={row.original.id}
								/>
							);
						})}
					</TableBody>

					<BottomRow>
						<Pagination>
							<span>Rows per page:</span>
							<select onChange={e => configurePageSize(Number(e.target.value))}>
								{[15, 25, 50, 100, 200, 300].map(pageSize => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
							<div>
								{page.length > 0 && `${getPageMin()}–${getPageMax()} of ${rows.length}`}
							</div>
							<button
								disabled={!canPreviousPage}
								onClick={() => {
									if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
									previousPage();
								}}>
								{'<'}
							</button>
							<button
								disabled={!canNextPage}
								onClick={() => {
									if (canNextPage) setControlledPageIndex(prev => prev + 1);
									nextPage();
								}}>
								{'>'}
							</button>
						</Pagination>
					</BottomRow>
				</TableContainer>
			) : (
				<Graphs filteredLeads={rows.map(row => row.original as Lead)} />
			)}
		</Container>
	) : (
		<LoadingScreen message="Loading leads..." />
	);
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;

	background-color: #17181b;
	width: 100%;
	max-height: 100%;
`;

const HeaderRow = styled.div`
	flex-shrink: 0;

	background-color: #202124;
	border-bottom: 2px solid #ffb310;
	color: #f6f6f6;

	overflow: hidden;
	z-index: 1;

	padding-right: 5px;
`;

const TableBody = styled.div`
	flex-shrink: 1;
	overflow-x: auto;
`;

const BottomRow = styled.div`
	flex-shrink: 0;

	padding: 5px;
	background-color: #202124;
	color: #f6f6f6;
`;

const Pagination = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
	width: fit-content;
	gap: 10px;
`;
