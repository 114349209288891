import { FieldLabel } from '../../../../../screens/ProjectInfo';
import FileDnd from '../../../../reusable-components/FileDnd';
import { FileType } from '../../../../reusable-components/DeliverableFiles/types';
import { useContext } from 'react';
import { StatusTableContext } from '../../../../reusable-components/ProjectStatusTables/StatusTableProvider';
import { AuthContext } from '../../../../../context/AuthProvider';
import DocusignButton from '../DocusignButton';
import GenerateProposalButton from '../GenerateProposalButton';
import styled from 'styled-components';

export default function FileDndField({
	field,
	showDocusign,
}: {
	field: FileType;
	showDocusign?: boolean;
}) {
	const { user } = useContext(AuthContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	return !user?.isContractor ? (
		<div className="infoRow">
			<FieldLabel label={labelMap[field]} />

			<FileDnd field={field} setProjects={setStatusProjects} />

			{!!showDocusign && (
				<ButtonContainer>
					<DocusignButton />
					<GenerateProposalButton />
				</ButtonContainer>
			)}
		</div>
	) : null;
}

const labelMap: { [key in FileType]: string } = {
	proposal: 'Proposals',
	existingDocs: 'Existing Documentation',
	pointCloud: 'Point Cloud Files',
	photos: 'Photo Files',
	pdf: 'PDF Files',
	autodesk: '3D / 2D Files',
};

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;
