import { FC, PropsWithChildren, useContext, useState } from 'react';
import { FormControl } from '@mui/material';
import { SelectFieldProps } from './SelectField.types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { StyledMenuItem, StyledSelect } from './Select.styles';
import theme from '../../../styles/theme';
import { TemplateContext } from '../../../context/TemplateProvider';

const SelectField: FC<PropsWithChildren<SelectFieldProps>> = ({
	node,
	size = 'medium',
}) => {
	const options: string[] = node.options || [];
	const nodeValue = node.value as string;
	const { updateValue } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const [value, setValue] = useState<string>(nodeValue ?? '');

	const handleChange = async (option: any) => {
		setValue(option);
		await updateValue(node.id, option);
	};

	return (
		<FormControl
			id={node.id}
			sx={{
				minWidth: 312,
				[theme.breakpoints.down('md')]: {
					width: '100%',
					minWidth: 'unset',
				},
			}}>
			<StyledSelect
				value={value}
				autoWidth
				placeholder="Select Option"
				onChange={event => handleChange(event.target.value)}
				displayEmpty
				size={size}
				disabled={isTemplate}
				inputProps={{
					'aria-label': 'Select Option',
					MenuProps: {
						MenuListProps: {
							sx: {
								backgroundColor: '#121212',
							},
						},
					},
				}}
				sx={{ color: value?.length > 0 ? '#fbfbfb' : '#838383' }}>
				<StyledMenuItem value="">None</StyledMenuItem>
				{options.map((option, index) => (
					<StyledMenuItem key={index} value={option}>
						{option}
					</StyledMenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
};

export default SelectField;
