import React, { FC, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../../styles/theme';

type Props = {
	size?: number;
	completedFields: number;
	requiredFields: number;
};

const RequiredProgress: FC<Props> = ({
	size = 60,
	completedFields,
	requiredFields,
}: Props) => {
	const [percentageProgress, setPercentageProgress] = React.useState<number>(0);

	useEffect(() => {
		setPercentageProgress((completedFields / requiredFields) * 100);
	}, [completedFields, requiredFields]);

	const THICKNESS = 4;

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				variant="determinate"
				value={percentageProgress}
				size={size}
				thickness={THICKNESS}
				sx={{
					borderRadius: '50%',
					boxShadow: `inset 0 0 0 ${(THICKNESS / 44) * size}px ${
						theme.palette.grey[700]
					}`,
				}}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography
					variant="caption"
					component="div"
					sx={{ color: theme.palette.grey[100] }}>{`${Math.round(
					percentageProgress
				)}%`}</Typography>
			</Box>
		</Box>
	);
};

export default RequiredProgress;
