import React, { FC, memo, useContext, useMemo, useState } from 'react';
import { InputFieldContainer, ManageUploadsButton } from './FormFillV2.styles';
import DraggableGallery from './PhotosField/DraggableGallery';
import styled from 'styled-components';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { SnackContext } from '../../../context/SnackProvider';
import {
	Flag,
	FormNode,
	LocalPhoto,
	PhotoV2,
	OCRModelNumberType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FilePresent } from '@mui/icons-material';
import PhotoGalleryDialog from './PhotoGalleryDialog/PhotoGalleryDialog';
import PhotoUploadManager from './PhotoUploadManager/PhotoUploadManager';
import theme from '../../../styles/theme';
import { Typography } from '@mui/material';
import { TemplateContext } from '../../../context/TemplateProvider';
import { FlattenedItem } from '../../screen-components/Form/components/FormWrapper/types';

type PhotosFieldProps = {
	itemNode: FormNode;
	orderedItems: FlattenedItem[];
};

const PhotosField: FC<PhotosFieldProps> = memo(({ itemNode, orderedItems }) => {
	const {
		addNewNode,
		addPhotosToDownloadQueue,
		deleteNodePhoto,
		photoUploadQueue,
		updatePhotos,
	} = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [openUploadManager, setOpenUploadManager] = useState(false);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [initialImageIndex, setInitialImageIndex] = useState(0);
	const [isUploading, setIsUploading] = useState(false);

	const uploadedPhotos = useMemo<PhotoV2[]>(
		() => (itemNode.value && Array.isArray(itemNode.value) ? itemNode.value : []),
		[itemNode.value]
	);

	const queuedPhotos = useMemo<PhotoV2[]>(
		() =>
			photoUploadQueue?.filter(p => p?.nodeId === itemNode.id).map(p => p.photo) || [],
		[itemNode.id, photoUploadQueue]
	);

	const combinedPhotos = useMemo(
		() => [...uploadedPhotos, ...queuedPhotos],
		[uploadedPhotos, queuedPhotos]
	);

	const draggingDisabled = useMemo(
		() => combinedPhotos.some(photo => !photo.url),
		[combinedPhotos]
	);

	const openGallery = (index: number) => {
		setInitialImageIndex(index);
		setIsGalleryOpen(true);
	};

	const closeGallery = () => setIsGalleryOpen(false);

	const deleteImage = async (index: number) => {
		const photoToDelete = uploadedPhotos[index];
		if (!photoToDelete) {
			console.error("Can't find photo.");
			return;
		}
		try {
			await deleteNodePhoto(itemNode.id, photoToDelete);
			setSnackbarProps({
				open: true,
				message: 'Photo deleted successfully.',
				severity: 'success',
			});
		} catch (error) {
			console.error('Failed to delete photo:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to delete photo. Please try again later.',
				severity: 'error',
			});
		}
	};

	const setFlag = (index: number, flag: Flag) => {
		const photos = uploadedPhotos.map((photo, i) =>
			i === index ? { ...photo, flag } : photo
		);
		try {
			updatePhotos(itemNode.id, photos);
		} catch (error) {
			console.error('Failed to update flag:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to update flag. Please try again later.',
				severity: 'error',
			});
		}
	};

	const handlePhotoUpload = async (newPhotos: File[]) => {
		setIsUploading(true);
		try {
			const localPhotos: LocalPhoto[] = newPhotos.map(file => ({
				originalName: file.name,
				uri: URL.createObjectURL(file),
			}));
			await addPhotosToDownloadQueue(itemNode.id, localPhotos);
		} catch (error) {
			console.log(error);
		} finally {
			setIsUploading(false);
		}
	};

	const handleCreateOCR = async (index: number) => {
		if (!uploadedPhotos[index].url) {
			console.error("Can't find photo.");
			return;
		}

		const value: OCRModelNumberType = {
			serialNumber: '',
			modelNumber: '',
			manufactureDate: '',
			manufacturer: '',
			photo: uploadedPhotos[index],
		};
		await addNewNode('OCR', 'modelNumber', [], itemNode.parentId, value);
	};

	return (
		<Container>
			<InputFieldContainer>
				<ManageUploadsButton onClick={() => !isTemplate && setOpenUploadManager(true)}>
					<FilePresent />
					<Typography
						fontWeight={700}
						sx={{
							[theme.breakpoints.down('sm')]: {
								fontSize: 12,
								textAlign: 'center',
							},
						}}>
						Manage uploads
					</Typography>
				</ManageUploadsButton>

				<PhotoUploadManager
					open={openUploadManager}
					onClose={() => setOpenUploadManager(false)}
					itemNode={itemNode}
					uploadedPhotos={uploadedPhotos}
					orderedItems={orderedItems}
				/>

				<DraggableGallery
					key={itemNode.id}
					photos={combinedPhotos}
					nodeId={itemNode.id}
					openGallery={openGallery}
					disabled={draggingDisabled}
					onUpload={handlePhotoUpload}
					isUploading={isUploading}
				/>

				<PhotoGalleryDialog
					open={isGalleryOpen}
					photos={uploadedPhotos}
					initialIndex={initialImageIndex}
					onClose={closeGallery}
					onDelete={deleteImage}
					setFlag={setFlag}
					createOCR={handleCreateOCR}
				/>
			</InputFieldContainer>
		</Container>
	);
});

export default PhotosField;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;
