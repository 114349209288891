import React, { useContext, useEffect, useState } from 'react';
import { Section as SectionContainer } from './Section.styles';
import { BlockMenuButton } from '../BlockMenuButton';
import { SectionItemProps } from './Section.types';
import { Box } from '@mui/system';
import { AddElementDialog } from '../AddElementDialog';
import { NodeType } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormTextEditField } from '../FormTextEditField';
import { SettingsContext } from '../../../context/SettingsProvider';
import styled from 'styled-components';
import FormNodeWrapper from '../FormNodeWrapper';
import { InputField } from '../InputField';
import { MultilineField } from '../MultilineField';
import { SwitchField } from '../SwitchField';
import { PhotosField } from '../PhotosField';
import { DateField } from '../DateField';
import { DateTimeField } from '../DateTimeField';
import { SelectField } from '../SelectField';
import Section from './Section';
import { YellowIconButton } from '../../styled-components/buttons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from '@mui/material';
import { WrapperIconButton } from '../FormNodeWrapper/FormNodeWrapper.styles';
import { ActionButton, CommentsButton, FlagButton } from '../FormNodeWrapper/buttons';
import { TemplateContext } from '../../../context/TemplateProvider';
import OCRModelNumber from '../OCRModelNumber/OCRModelNumber';

const SectionExist: React.FC<SectionItemProps> = ({
	level,
	node,
	itemsByParentId,
	expandedList,
	toggleCollapse,
	orderedItems,
}) => {
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);
	const { isTemplate } = useContext(TemplateContext);

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [excludedTypes, setExcludedTypes] = useState<NodeType[]>([]);
	const [collapsedPressed, setCollapsedPressed] = useState<boolean>(false);

	const addElem = (type: string) => {
		if (type === 'input') {
			setShowDialog(true);
			setExcludedTypes(['node']);
		} else {
			setShowDialog(true);
			setExcludedTypes([
				'input',
				'area',
				'select',
				'switch',
				'date',
				'datetime',
				'photos',
			]);
		}
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const children = itemsByParentId[node.id] || [];

	const [collapsed, setCollapsed] = useState<boolean>(!expandedList.includes(node.id));

	useEffect(() => {
		setCollapsed(!expandedList.includes(node.id));
	}, [expandedList, node.id]);

	return (
		<SectionContainer>
			<Header level={node.level ? node.level + 1 : 1}>
				<YellowIconButton
					onClick={() => [
						toggleCollapse(node.id),
						setCollapsedPressed(!collapsedPressed),
					]}
					title={collapsed ? 'Expand section' : 'Collapse section'}
					style={{ marginTop: '2px' }}>
					{collapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
				</YellowIconButton>

				<Box>
					<BlockMenuButton
						onChange={addElem}
						disable={level >= maxNodeContainerNestingLevel ? ['section'] : []}
						small
					/>
				</Box>
				{isTemplate ? (
					<Box width={'10px'}></Box>
				) : (
					<WrapperIconButton>
						<FlagButton node={node} />
					</WrapperIconButton>
				)}

				<Box width={'100%'}>
					<FormTextEditField node={node} isName nodeField="displayTitle" />
					{!!node.info && (
						<Typography variant="caption" color="grey.300">
							{node.info}
						</Typography>
					)}
				</Box>
				{!isTemplate && (
					<WrapperIconButton>
						<CommentsButton node={node} />
					</WrapperIconButton>
				)}
				<WrapperIconButton>
					<ActionButton
						node={node}
						collapsedPressed={collapsedPressed}
						setCollapsedPressed={setCollapsedPressed}
					/>
				</WrapperIconButton>
			</Header>

			{!collapsed
				? children.map(item => {
						return (
							<FormLevelContainer
								type={item.node.type}
								level={item.depth}
								key={item.node.id}>
								<FormNodeWrapper node={item.node}>
									{item.node.type === 'input' ? (
										<InputField node={item.node} size="small" />
									) : item.node.type === 'area' ? (
										<MultilineField node={item.node} size="small" />
									) : item.node.type === 'switch' ? (
										<SwitchField itemNode={item.node} />
									) : item.node.type === 'photos' ? (
										<PhotosField orderedItems={orderedItems} itemNode={item.node} />
									) : item.node.type === 'date' ? (
										<DateField node={item.node} size="small" />
									) : item.node.type === 'datetime' ? (
										<DateTimeField node={item.node} size="small" />
									) : item.node.type === 'select' ? (
										<SelectField node={item.node} size="small" />
									) : item.node.type === 'modelNumber' ? (
										<OCRModelNumber node={item.node} />
									) : (
										<Section
											node={item.node}
											level={item.depth + 1}
											itemsByParentId={itemsByParentId}
											expandedList={expandedList}
											toggleCollapse={toggleCollapse}
											orderedItems={orderedItems}
										/>
									)}
								</FormNodeWrapper>
							</FormLevelContainer>
						);
				  })
				: null}

			{!collapsed && (
				<Box sx={{ pl: '12px', pb: '4px' }}>
					<BlockMenuButton
						onChange={addElem}
						disable={level >= maxNodeContainerNestingLevel ? ['section'] : []}
					/>
				</Box>
			)}

			<AddElementDialog
				open={showDialog}
				node={node}
				handleClose={handleClose}
				excludedTypes={excludedTypes}
			/>
		</SectionContainer>
	);
};

export default SectionExist;

const Header = styled.div<{ level?: number }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const FormLevelContainer = styled(Box)<{ type: string; level: number }>`
	padding-left: ${({ type, level }) => (type === 'node' && level > 0 ? '45px' : '45px')};
`;
