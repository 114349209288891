import { styled, useMediaQuery } from '@mui/system';
import { FC, useContext, useState } from 'react';
import { CoverImage } from '../CoverImage';
import { useParams } from 'react-router-dom';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { Business, Group, Lock } from '@mui/icons-material';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { BackButton } from '../../reusable-components/BackButton';
import Tools from './buttons/Tools';
import Download from './buttons/Download';
import Submit from './buttons/Submit';
import SwitchForm from './buttons/SwitchForm';
import { YellowButton } from '../../styled-components/styledReactTable';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { StyledIconButton } from './buttons/components';
import MenuItemContent from '../MenuItem/MenuItem';
import theme from '../../../styles/theme';
import { FormDialog } from '../../../screens/NodesReorder/components/FormDialog';
import { AssignDialog } from '../../../screens/NodesReorder/components/FormDialog/AssignDialog';
import { TemplateContext } from '../../../context/TemplateProvider';
import { PDF } from './buttons/PDF';

const FormHeader: FC = () => {
	const matches = useMediaQuery('(max-width:1024px)');
	const { utilityForm } = useContext(FormV2Context);
	const { isTemplate, templateName } = useContext(TemplateContext);
	const { projectId }: any = useParams();

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isAssignDialogOpen, setIsAssignDialogOpen] = useState<boolean>(false);
	const [assignType, setAssignType] = useState<'project' | 'team'>('project');

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};

	const handleAssignDialog = () => {
		setIsAssignDialogOpen(!isAssignDialogOpen);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Container>
			{matches && (
				<BackButton
					url={`/projects/${projectId}`}
					buttonText="Back to Project"
					noMargin
				/>
			)}
			{isTemplate ? (
				<>
					<HeaderTitleContainer>
						<HeaderTitle>{templateName}</HeaderTitle>
						<IconsContainer>
							<YellowButton
								aria-describedby={id}
								variant="contained"
								onClick={handleClick}>
								Assign Template
							</YellowButton>
							<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
								<CustomMenuItem
									onClick={() => {
										setAssignType('project');
										handleAssignDialog();
										handleClose();
									}}>
									<MenuItemContent
										title="Assign to Project(s)"
										startIcon={<Business color="primary" />}
									/>
								</CustomMenuItem>
								<CustomMenuItem
									onClick={() => {
										setAssignType('team');
										handleAssignDialog();
										handleClose();
									}}>
									<MenuItemContent
										title="Assign to Team(s)"
										startIcon={<Group color="primary" />}
									/>
								</CustomMenuItem>
							</CustomMenu>
							<StyledIconButton onClick={handleDialog}>
								<NoteAddIcon color="primary" />
							</StyledIconButton>
							<FormDialog open={isDialogOpen} handleClose={handleDialog} />
							<AssignDialog
								open={isAssignDialogOpen}
								type={assignType}
								handleClose={handleAssignDialog}
							/>
						</IconsContainer>
					</HeaderTitleContainer>
					<Title>Report Content</Title>
					<Description>
						Add blocks to create sections and inputs for this report.
					</Description>
				</>
			) : (
				<>
					<HeaderTitleContainer>
						<HeaderTitle>{utilityForm?.formName}</HeaderTitle>
						<IconsContainer>
							{utilityForm?.locked && (
								<Tooltip title="The form cannot be submitted while uploading photos">
									<Lock color="primary" />
								</Tooltip>
							)}
							{/* <ToolDropdown /> */}
							<SwitchForm />
							<Tools />
							<Download />
							{!!utilityForm && !!utilityForm.downloadUrlVirtual && (
								<PDF link={utilityForm.downloadUrlVirtual} />
							)}
							<Submit />
						</IconsContainer>
					</HeaderTitleContainer>
					<Title>Cover images</Title>
					<Description>
						Add images to be displayed on the cover page of the analytics generated from
						this report.
					</Description>
					<ImagesContainer>
						<CoverImage hasLogo />
					</ImagesContainer>
					<Title>Report Content</Title>
					<Description>
						Add blocks to create sections and inputs for this report.
					</Description>
				</>
			)}
		</Container>
	);
};

const Container = styled('div')`
	background-color: ${({ theme }) => theme.palette.secondary.main};
	color: ${({ theme }) => theme.palette.newText.primary};
`;

const HeaderTitle = styled('div')`
	font-size: 32px;
	line-height: 40px;
`;

const HeaderTitleContainer = styled('div')`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

const Title = styled('div')`
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
`;

const Description = styled('div')`
	font-size: 14px;
	line-height: 20px;
`;

const IconsContainer = styled('div')`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const ImagesContainer = styled('div')`
	margin-top: 28px;
	margin-bottom: 28px;
`;

const CustomMenu = styled(Menu)`
	top: 8px;

	.MuiPaper-root {
		background-color: ${theme.palette.secondary.main};
	}
`;

const CustomMenuItem = styled(MenuItem)`
	padding: 0;
	white-space: normal;
`;

export default FormHeader;
