import { useEffect, useContext, useState } from 'react';
import Layout from '../components/reusable-components/Layout';
import { AuthContext } from '../context/AuthProvider';
import { UserWithData } from '../types';
import DataTable from '../components/reusable-components/DataTable';
import SkeletonTable from '../components/reusable-components/SkeletonTable';
import styled from 'styled-components';
import getUserColumns from '../components/screen-components/UserManagement/columns';
import UserPermsDialog from '../components/screen-components/UserManagement/UserPermsDialog';
import UserTagsDialog from '../components/screen-components/UserManagement/UserTagsDialog';
import { FilterActions } from '../components/screen-components/UserManagement/FilterActions';
import { UserTag } from '../types/project-roles';

export default function UserManagement() {
	const { usersWithData, fetchingUsers } = useContext(AuthContext);
	const [userPermsEdit, setUserPermsEdit] = useState<UserWithData | null>(null);
	const [userTagsEdit, setUserTagsEdit] = useState<UserWithData | null>(null);
	const [showFilterBar, setShowFilterBar] = useState(false);
	const [selectedTags, setSelectedTags] = useState<UserTag[]>([]);

	const resetTags = () => {
		setSelectedTags([]);
	};

	useEffect(() => {
		document.title = 'Neural Net | Robotic Imaging';
	}, []);

	const userColumns = getUserColumns(setUserPermsEdit, setUserTagsEdit);

	const handlePermsClose = () => {
		setUserPermsEdit(null);
	};

	const handleTagsClose = () => {
		setUserTagsEdit(null);
	};

	const toggleFilterBar = () => setShowFilterBar(prev => !prev);

	const handleTagFilterChange = (tag: UserTag) => {
		setSelectedTags(prev => {
			if (prev.includes(tag)) {
				return prev.filter(t => t !== tag);
			} else {
				return [...prev, tag];
			}
		});
	};

	return (
		<Layout title="Neural Net">
			{fetchingUsers ? (
				<SkeletonContainer>
					<SkeletonTable />
				</SkeletonContainer>
			) : (
				<>
					<TableWrapper>
						<DataTable
							data={usersWithData}
							columns={userColumns}
							queryFields={['fullName', 'email']}
							name={''}
							showButton
							showFilterButton
							onFilterSelect={toggleFilterBar}
							additionalActions={
								<FilterActions
									showFilterBar={showFilterBar}
									toggleFilterBar={toggleFilterBar}
									selectedTags={selectedTags}
									onTagFilterChange={handleTagFilterChange}
									resetTags={resetTags}
								/>
							}
							selectedTags={selectedTags}
							onTagFilterChange={handleTagFilterChange}
							disabledField="disabled"
						/>
					</TableWrapper>

					<UserPermsDialog
						open={!!userPermsEdit}
						onClose={handlePermsClose}
						setUserPermsEdit={setUserPermsEdit}
						userPermsEdit={userPermsEdit}
					/>

					<UserTagsDialog
						open={!!userTagsEdit}
						onClose={handleTagsClose}
						setUserTagsEdit={setUserTagsEdit}
						userTagsEdit={userTagsEdit}
					/>
				</>
			)}
		</Layout>
	);
}

const SkeletonContainer = styled.div`
	max-width: 640px;
	width: 100%;
	margin: auto;
`;

const TableWrapper = styled.div`
	display: block;
	border-radius: 20px;
	margin: 0 auto;

	width: 100%;
	height: 100%;
`;
