import { FC, useContext, useEffect, useState } from 'react';
import { NavigationMenu } from '../NavigationMenu';
import { FormNavigationMenuProps } from './FormNavigationMenu.types';
import { styled } from '@mui/material';
import { BackButton } from '../../reusable-components/BackButton';
import useFormNodes from '../../screen-components/Form/components/FormWrapper/useFormNodes';
import { addOrderToItems } from '../../../utils/addFormOrder';
import { FormNode } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FlattenedItem, TreeItem, TreeItems } from '../NavigationMenu/Tree/types';
import { UniqueIdentifier } from '@dnd-kit/core';
import useFormTemplateNodes from '../../screen-components/Form/components/FormWrapper/useFormTemplateNodes';
import { TemplateContext } from '../../../context/TemplateProvider';
import { useLocation } from 'react-router-dom';

const FormNavigationMenu: FC<FormNavigationMenuProps> = ({
	formId,
	onClick,
	projectId,
}) => {
	const { isTemplate, prevURL } = useContext(TemplateContext);
	const { allFormNodes } = useFormNodes(formId ?? '', projectId ?? '');
	const { allFormTemplateNodes } = useFormTemplateNodes(formId ?? '');
	const nodes = isTemplate ? allFormTemplateNodes : allFormNodes;
	const [items, setItems] = useState<TreeItems>([] as TreeItems);
	const location = useLocation();

	const buttonURL = () => {
		if (isTemplate) {
			if (
				prevURL.pathname &&
				prevURL.pathname !== location.pathname &&
				prevURL.pathname !== '/login'
			) {
				return prevURL.pathname;
			}
			return '/projects';
		} else {
			return `/projects/${projectId}`;
		}
	};

	useEffect(() => {
		if (!!nodes && nodes.length) {
			const treeItems = formNodesToTreeItems(nodes);
			const flattenedTree = flattenTree(treeItems);
			const orderedResult = addOrderToItems(flattenedTree, true);
			const orderedTreeItems = formNodesToTreeItems(orderedResult.map(item => item.node));
			const items = orderedTreeItems.map(treeItem => {
				return {
					...treeItem,
					expanded: true,
					node: {
						...treeItem.node,
					},
				} as TreeItem;
			}) as TreeItems;
			setItems(items);
		}
	}, [nodes]);

	return (
		<MenuContainer>
			<BackButton
				url={buttonURL()}
				buttonText={isTemplate ? 'Return back' : 'Back to Project'}
			/>
			<NavigationMenu
				title="OUTLINE"
				items={items}
				onClick={onClick}
				style={{ margin: '20px' }}
			/>
		</MenuContainer>
	);
};

export function formNodesToTreeItems(formNodes: FormNode[]): TreeItem[] {
	if (!formNodes) return [];
	const formNodesByParentId: Record<string, FormNode[]> = {};
	for (const formNode of formNodes) {
		if (!formNode.parentId) {
			formNodesByParentId['root'] = [...(formNodesByParentId['root'] || []), formNode];
		} else {
			formNodesByParentId[formNode.parentId] = [
				...(formNodesByParentId[formNode.parentId] || []),
				formNode,
			];
		}
	}
	const orderedRootNodes = sortSiblingFormNodes(formNodesByParentId['root'] || []);
	const treeItems = orderedRootNodes.map(formNode =>
		buildTreeItem(formNode, formNodesByParentId)
	);
	return treeItems;
}

function sortSiblingFormNodes(formNodes: FormNode[]) {
	return formNodes.sort((a, b) => (a.order || 0) - (b.order || 0));
}

function buildTreeItem(
	formNode: FormNode,
	formNodesByParentId: Record<string, FormNode[]>
): TreeItem {
	const formNodeChildren = sortSiblingFormNodes(formNodesByParentId[formNode.id] || []);
	const treeItemChildren: TreeItem[] = [];
	for (const child of formNodeChildren) {
		treeItemChildren.push(buildTreeItem(child, formNodesByParentId));
	}
	return {
		id: formNode.id,
		children: treeItemChildren,
		expanded: true,
		node: formNode,
	};
}

export function flattenTree(
	items: TreeItem[],
	parentId: UniqueIdentifier | null = null,
	depth = 0
): FlattenedItem[] {
	return items.reduce<FlattenedItem[]>((acc, item, index) => {
		const updatedItem = {
			...item,
			parentId,
			depth,
			index,
			node: { ...item.node, level: depth },
		};
		return [
			...acc,
			{ ...updatedItem, parentId, depth, index },
			...flattenTree(item.children, item.id, depth + 1),
		];
	}, []);
}

const MenuContainer = styled('div')`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export default FormNavigationMenu;
