import { useContext, useState } from 'react';
import { CalendarIcon, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { InputAdornment } from '@mui/material';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { DateFieldProps } from './DateField.types';
import theme from '../../../styles/theme';
import { TemplateContext } from '../../../context/TemplateProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateField: React.FC<React.PropsWithChildren<DateFieldProps>> = ({
	node,
	size = 'medium',
}) => {
	const [value, setValue] = useState<Dayjs | null>(
		node.value && typeof node.value === 'object' && 'toDate' in node.value
			? dayjs(node.value.toDate())
			: null
	);
	const [open, setOpen] = useState(false);
	const { updateValue } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);

	const handleDateChange = async (newValue: Dayjs | null) => {
		setOpen(false);
		setValue(newValue);
		if (newValue && node.id) {
			const dateForFirestore = newValue.hour(12).minute(0).second(0).utc().toDate();
			await updateValue(node.id, dateForFirestore);
		} else if (node.id) {
			await updateValue(node.id, '');
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label="Select Date"
				open={open}
				value={value}
				onChange={handleDateChange}
				onClose={() => setOpen(false)}
				disabled={isTemplate}
				slotProps={{
					textField: {
						id: node.id,
						size,
						InputProps: {
							readOnly: true,
							size,
							endAdornment: (
								<InputAdornment
									sx={{
										color: theme.palette.grey[300],
									}}
									position="end">
									<CalendarIcon />
								</InputAdornment>
							),
						},
						onClick: () => setOpen(true),
					},
					popper: {
						sx: {
							...pickerStyles,
						},
					},
				}}
				sx={{
					...inputStyles,
					minWidth: 312,
					[theme.breakpoints.down('md')]: {
						width: '100%',
						minWidth: 'unset',
					},
				}}
			/>
		</LocalizationProvider>
	);
};

const pickerStyles = {
	borderRadius: '16px',
	overflow: 'hidden',
	'.MuiSvgIcon-root': {
		fill: theme.palette.grey[300],
	},
	'.MuiPickersLayout-contentWrapper, .MuiPaper-root': {
		backgroundColor: theme.palette.secondary.main,
	},
	'.MuiPickersCalendarHeader-label, .MuiDayCalendar-weekDayLabel, .MuiPickersDay-root, .MuiPickersYear-yearButton':
		{
			color: theme.palette.newText.primary,
		},
	'.MuiPickersDay-root.Mui-selected, .MuiPickersYear-yearButton.Mui-selected': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.black,
	},
};

const inputStyles = {
	input: {
		color: theme.palette.newText.primary,
		'&::placeholder': {
			lineHeight: '1.6',
		},
	},
	fieldset: {
		borderColor: theme.palette.grey[800],
	},
	label: {
		color: theme.palette.grey[300],
		lineHeight: 1.6,
	},
	'&:hover': {
		'> :not(.Mui-focused)': {
			fieldset: {
				borderColor: theme.palette.common.white,
			},
		},
	},

	'.MuiInputBase-root': {
		'&.Mui-disabled': {
			border: '1px solid rgba(118, 118, 118, 0.3)',
			borderRadius: '5px',
		},
	},
};

export default DateField;
