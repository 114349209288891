import { ColumnInstance, Row } from 'react-table';
import { ProjectData, ProjectStatus } from '../../../../../types';

export const excludedStatuses: ProjectStatus[] = [
	'Pending Payment / Delivered',
	'Delivered',
	'Pending Payment',
	'Sample',
	'Template',
	'Archive',
];

const excludedFields: (keyof ProjectData)[] = ['storeNumber'];

export const hasValue = (value: any) => {
	if (typeof value === 'string') return !!value;
	else if (typeof value === 'number' || typeof value === 'boolean') return true;
	else if (Array.isArray(value)) return !!value.length;
	else return !!value;
};

const bimExcludedFields: (keyof ProjectData)[] = ['storeNumber', 'qaQcManagers'];

const bimExcludedStatuses: ProjectStatus[] = [
	'Registration',
	'Ready For Modeling',
	'Modeling In Progress',
];

export const isRowComplete = (row: Row<ProjectData>) => {
	const projectData = row.original;
	const projectStatus = projectData.projectStatus;

	if (excludedStatuses.includes(projectStatus)) return true;

	const columns = row.cells.map(cell => cell.column) as (ColumnInstance<ProjectData> & {
		projectField?: keyof ProjectData | null;
	})[];

	for (const column of columns) {
		const field = column.projectField;

		if (field === null || field === undefined || excludedFields.includes(field)) {
			continue;
		} else if (
			bimExcludedStatuses.includes(projectStatus) &&
			bimExcludedFields.includes(field)
		) {
			continue;
		} else if (!hasValue(projectData[field])) {
			return false;
		}
	}
	return true;
};

export const defaultPageSize = 20;
