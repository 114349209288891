import { useState, useEffect, useContext, useCallback } from 'react';
import { AccountManager, TimeValue } from '../types';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { OrganizationsContext } from '../context/OrganizationsProvider';
import { uploadLogo } from '../utils/organization-file-upload';
import { SnackContext } from '../context/SnackProvider';
import { Sidebar } from '../components/reusable-components/Sidebar';
import Layout from '../components/reusable-components/Layout';
import Name from '../components/screen-components/OrganizationPage/field-components/Name';
import styled from 'styled-components';
import AdminNotes from '../components/screen-components/OrganizationPage/field-components/AdminNotes';
import OrgNotes from '../components/screen-components/OrganizationPage/field-components/OrgNotes';
import LogoContainer from '../components/reusable-components/LogoContainer/LogoContainer';
import PaymentTerms from '../components/screen-components/OrganizationPage/field-components/PaymentTerms';
import HostingTerms from '../components/screen-components/OrganizationPage/field-components/HostingTerms';
import TeamsTab from '../components/screen-components/OrganizationPage/TeamTab';
import OwnerTab from '../components/screen-components/OrganizationPage/OwnerTab';
import AccountManagerSelect from '../components/screen-components/OrganizationPage/field-components/AccountManager';
import NonAdminOrgPage from '../components/screen-components/OrganizationPage/NonAdminOrgPage';

export default function OrganizationPage() {
	const { organizationId }: any = useParams();
	const { user } = useContext(AuthContext);
	const {
		loadOrganization,
		loadingOrganization,
		organization,
		setOrganization,
		updateOrganization,
		updatingOrganization,
		updateLogoUrl,
	} = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [isActive, setIsActive] = useState(false);
	const [adminNotes, setAdminNotes] = useState('');
	const [orgNotes, setOrgNotes] = useState('');
	const [paymentTerms, setPaymentTerms] = useState<number | undefined>(
		organization?.paymentTerms
	);
	const [hostingTerms, setHostingTerms] = useState<TimeValue | undefined>(
		organization?.hostingTerms
	);
	const [accountManager, setAccountManager] = useState<AccountManager | undefined>();

	const defaultTitle = 'Loading Organization...';

	useEffect(() => {
		if (organizationId) {
			loadOrganization(organizationId);
		}
	}, [loadOrganization, organizationId]);

	useEffect(() => {
		if (organization) {
			setAdminNotes(organization.adminNotes || '');
			setOrgNotes(organization.orgNotes || '');
			setPaymentTerms(organization.paymentTerms);
			setHostingTerms(organization.hostingTerms);
			setAccountManager(organization.accountManager);
		}
	}, [organization]);

	useEffect(() => {
		if (organization && organization.name) {
			document.title = `${organization.name} | Robotic Imaging`;
		} else {
			document.title = defaultTitle;
		}
	}, [organization]);

	const handleLogoUpload = useCallback(
		async (files: File[]) => {
			if (files.length > 0) {
				try {
					// Pass the old logo if it exists
					const oldLogo = organization?.logo?.[0];
					const downloadUrl = await uploadLogo(
						files[0],
						organization?.id as string,
						setOrganization,
						oldLogo
					);
					updateLogoUrl(downloadUrl);
				} catch (error) {
					console.error('Error uploading logo', error);
				}
			}
		},
		[organization, setOrganization, updateLogoUrl]
	);

	const handleSubmit = async () => {
		setIsActive(false);
		if (organization) {
			const updatedOrganization = {
				...organization,
				adminNotes,
				orgNotes,
				paymentTerms,
				hostingTerms,
				accountManager,
			};
			await updateOrganization(updatedOrganization);
			setSnackbarProps({
				open: true,
				message: `Success! Organization has been updated.`,
				severity: 'success',
				hideDuration: 5000,
			});
		}
	};

	const handleAdminNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdminNotes(e.target.value);
	};

	const handleOrgNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setOrgNotes(e.target.value);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		if (organization && name && value)
			setOrganization({ ...organization, [name]: value });
	};

	const sidebarContent = (
		<>
			<Name handleChange={handleChange} isActive={isActive} />
			<OrgNotes handleChange={handleOrgNotesChange} isActive={isActive} />
			{user?.isAdmin && (
				<AdminNotes handleChange={handleAdminNotesChange} isActive={isActive} />
			)}
			<PaymentTerms
				isActive={isActive}
				handleChange={setPaymentTerms}
				paymentTerm={paymentTerms}
			/>
			<HostingTerms
				isActive={isActive}
				handleChange={setHostingTerms}
				timeValue={hostingTerms}
			/>
			<AccountManagerSelect
				isActive={isActive}
				value={accountManager}
				handleChange={setAccountManager}
			/>

			{updatingOrganization ? (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			) : (
				<ButtonsContainer>
					<Button
						className="button"
						type="submit"
						variant="contained"
						onClick={() => setIsActive(prev => !prev)}>
						{!isActive ? 'Edit' : 'Cancel'}
					</Button>
					{isActive ? (
						<Button
							className="button"
							type="submit"
							variant="contained"
							onClick={handleSubmit}>
							Submit
						</Button>
					) : null}
				</ButtonsContainer>
			)}
		</>
	);

	const [currTabIndex, setCurrTabIndex] = useState(0);
	const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
		setCurrTabIndex(newValue);
	};

	return (
		<Layout LeftNavComponent={<OrgTitle>{organization?.name}</OrgTitle>}>
			<OrganizationPageContainer>
				{!loadingOrganization ? (
					user?.isAdmin ? (
						<>
							<Sidebar
								backButtonTo="/organizations"
								backButtonTitle="Back to Organizations"
								bottomContent={<LogoContainer handleLogoUpload={handleLogoUpload} />}>
								{sidebarContent}
							</Sidebar>

							<OrganizationContent>
								<Tabs value={currTabIndex} onChange={handleTabChange}>
									<OrgPageTab label="Teams" />
									{(user?.userPerms?.organizations ||
										user?.orgOwnerIds?.includes(organization?.id as '')) && (
										<OrgPageTab label="Owners" />
									)}
								</Tabs>

								<OrgTabPanel index={0} currTabIndex={currTabIndex}>
									<TeamsTab />
								</OrgTabPanel>
								<OrgTabPanel index={1} currTabIndex={currTabIndex}>
									<OwnerTab />
								</OrgTabPanel>
							</OrganizationContent>
						</>
					) : (
						<NonAdminOrgPage />
					)
				) : (
					<LoadingPage>
						<CircularProgress size={50} />
					</LoadingPage>
				)}
			</OrganizationPageContainer>
		</Layout>
	);
}

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 19px;
	height: 70px;
`;

const LoadingPage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const OrganizationPageContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (max-width: 400px) {
		width: 100%;
		margin: 0;
		padding: 0;
	}
`;

export const OrganizationContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	flex-grow: 1;
	min-width: 0;

	height: 100%;
`;

const OrgPageTab = styled(Tab)`
	color: white;
`;

const OrgTabPanel = styled.div<{ index: number; currTabIndex: number }>`
	display: ${({ index, currTabIndex }) => (index === currTabIndex ? 'flex' : 'none')};
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: #111114;
`;

const OrgTitle = styled.h1`
	font-size: 22px;
	color: #f6f6f6;
	display: flex;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	margin: 10px 0;
`;
